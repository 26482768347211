<template>
  <div class="card is-shadowless" :style="{ 'background-color': cardBgColor }">
    <div class="card-content">
      <div class="content">
          <div class="is-size-6 has-text-weight-semibold">{{ cdate }}</div>
          <h4 class="title is-4 is-size-4-desktop is-size-3-widescreen mt-4 mb-1">{{ data.name }}</h4>
          <div class="is-size-7 desc">{{ data.description }}</div>

          <!-- <div class="progress-wrap mt-4 mb-3">
            <progress class="progress is-small is-success" value="20" max="100">20%</progress>
          </div>  -->
      </div>
    </div>
    <footer class="card-footer">
      <!-- <p class="card-footer-item">
        <span></span>
      </p> -->
      <p class="card-footer-item">
        <span>View</span>
      </p>
    </footer>
  </div>
</template>

<script>
import { reactive, computed } from 'vue'
import randomColor from 'randomcolor'
import { formatDateFilter } from "@/use/useFilter";
// import Box from '@/components/ds/Box.vue'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    randomColor: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {

    const state  = reactive(props.data);

    let cdate = formatDateFilter(state.created_at, 'DD.MM.YY')

    let cardBgColor = computed(() => {
        return props.randomColor ? randomColor({ luminosity: 'light' }) : '#fff'
    })

    return {
      cdate,
      state,
      cardBgColor
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 20px;
    .desc {
      min-height: 50px;
    }

    .title {
      min-height: 70px;
    }
  }

</style>